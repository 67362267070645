import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import BareLayout from '../components/BareLayout'
import MainContent from '../components/MainContent'
import Chimp from '../components/Chimp';

const description = 'Signs of the Sojourner is an upcoming indie PC game from Echodog Games. It is a narrative card game about relationships and communication.'

export default ({ data, location, location: { state } }) => (
    <div>
        <BareLayout title="Home" description={description} location={location}>
            <div className="hero is-fullheight-with-navbar">
                <div className="hero-body" style={{ padding: 0 }}>
                    <Img
                        fluid={data.bg.childImageSharp.fluid}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }}
                    />
                    <div class="container " id="trailerc">
                        <img
                            src='data:image/gif;base64,R0lGODlhEAAJAIAAAP///wAAACwAAAAAEAAJAAACCoSPqcvtD6OclBUAOw=='
                            id="trailer-image"
                        />
                        <iframe
                            width="960"
                            height="540"
                            src="https://www.youtube.com/embed/JR-yGRKr-J8"
                            title="trailer"
                            frameborder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            id="trailer-iframe"
                        >
                        </iframe>
                        <div class="is-hidden-touch" id="trailer-frame-parent">
                            <Img
                                fixed={data.frame.childImageSharp.fixed}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        </div>
                    </div>
                    <a
                        href="#target"
                        className="continue-down"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            bottom: 32,
                            width: 128,
                            height: 128,
                            marginLeft: -64,
                            zIndex: 2
                        }}
                    >
                        <Img fluid={data.continue.childImageSharp.fluid}
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                transform: 'rotate(90deg)',
                            }}
                        />
                    </a>
                    {/* <div className="game-logo-div-full">
                        <Logo className="game-logo"/>
                    </div>
                    <div className="game-logo-div-mobile">
                        <Logo className="game-logo"/>
                    </div> */}
                </div>
                <div id="target" style={{ position: 'absolute', bottom: 45 }}></div>
            </div>
            <MainContent parentClass="content">
                {/* <Trailer/> */}
                <div className="columns is-centered" style={{ marginTop: 20, marginBottom: -35 }}>
                    <div className="column has-text-centered" style={{ paddingTop: 0, paddingBottom: 0, justifyContent: 'center' }}>
                        <a href="https://store.steampowered.com/app/1058690/Signs_of_the_Sojourner" target="_blank" rel="nofollow noopener noreferrer">
                            <Img fluid={data.buysteam.childImageSharp.fluid}/>
                        </a>
                    </div>
                    <div className="column" style={{ paddingTop: 0, paddingBottom: 0, justifyContent: 'center' }}>
                        <a href="https://discord.gg/echodoggames" target="_blank" rel="nofollow noopener noreferrer">
                            <Img fluid={data.discord.childImageSharp.fluid}/>
                        </a>
                    </div>
                </div>
                <div className="blurb">
                    <p><b>Signs of the Sojourner</b> is a narrative card game about relationships and communication.</p>
                    <p><b>Encounters are conversations rather than combat.</b> Your ability to navigate social situations and understand different perspectives is based on an expressive card game experience.</p>
                </div>
                <Img
                    fluid={data.gameplay.childImageSharp.fluid}
                    className="has-box-shadow"
                    style={{ transform: 'rotate(-1deg)' }}
                />
                <div className="blurb">
                    <b>You'll travel through a diverse landscape</b>, meeting people and collecting regional foods and treats to bring back to your hometown shop. As your relationships grow you'll learn about their everyday problems, their friendships and rivalries, their ambitions and secrets.
                </div>
                <div className="columns">
                    <div className="column">
                        <Img
                            fluid={data.map.childImageSharp.fluid}
                            className="has-box-shadow"
                            style={{
                                transform: 'translate(0px, -10px) rotate(-1deg) scale(1.1)',
                            }}
                        />
                    </div>
                    <div className="column">
                        <Img
                            fluid={data.cycle.childImageSharp.fluid}
                            className="has-box-shadow"
                            style={{
                                transform: 'translate(0px, 10px) rotate(1deg) scale(1.1)',
                            }}
                        />
                    </div>
                </div>
                <div className="blurb">
                    <b>Find common ground or other ways to connect</b>, and learn to communicate with characters from different cultures and backgrounds. You may find that becoming well-versed in one communication style will open up opportunities, but stifle other possibilities.
                </div>
                <Img fluid={data.cards.childImageSharp.fluid}/>
                <div className="blurb">
                    <b>Your deck is your character</b>, shaped by your experiences and relationships. Different decks will lead to different narrative paths as your cards impact your ability to communicate with various characters. You will have to make decisions about who you want to be in this world and how you relate to others.
                </div>
                <div className="blurb">
                    Some more things you can expect to do in Signs of the Sojourner:
                    <ul>
                        <li>Explore curious locales</li>
                        <li>Learn the local lingo</li>
                        <li>Make friends</li>
                        <li>Make enemies</li>
                        <li>Play matchmaker</li>
                        <li>Collect all the snacks</li>
                        <li>Find romance</li>
                        <li>Discover ambitions and secrets and hidden pasts</li>
                        <li>Learn from and influence others</li>
                    </ul>
                </div>
            </MainContent>
            <div className="footer sub-footer">
                <div className="container">
                    <div className="columns">
                        <div className="column is-4 is-hidden-touch">
                            <Img
                                fluid={data.fish.childImageSharp.fluid} style={{
                                    maxWidth: 250,
                                    transform: 'translate(0px, -25px) scale(1.2)'
                                }}
                            />
                        </div>
                        <div className="column has-text-white" style={{ paddingBottom: 40}}>
                            <p style={{ marginTop: 20, marginBottom: 30 }}>
                                Signs of the Sojourner available now for Windows and Mac. To follow further development check out our <Link to="/blog"><b>devblog</b></Link>, follow us on <a href="https://twitter.com/EchodogGames" target="_blank" rel="nofollow noopener noreferrer"><b>twitter</b></a>, and sign up for our mailing list!
                            </p>
                            <Chimp focus={(state || { }).focusSubscribe}/>
                        </div>
                    </div>
                </div>
            </div>
        </BareLayout>
        <div id="subscribe"></div>
    </div>
 )

export const bigImage = graphql`
    fragment bigImage on File {
        childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
        }
    }
`

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`
export const pageQuery = graphql`
    query {
        thunder: file(relativePath: { eq: "thunderlogo.png" }) {
            ...fluidImage
        },
        bg: file(relativePath: { eq: "bg_tosendeCanals_dark.png" }) {
            ...bigImage
        },
        gameplay: file(relativePath: { eq: "gameplay16.png" }) {
            ...fluidImage
        },
        map: file(relativePath: { eq: "map4.png" }) {
            ...fluidImage
        },
        cycle: file(relativePath: { eq: "homeCycle1.png" }) {
            ...fluidImage
        },
        cards: file(relativePath: { eq: "cards1.png" }) {
            childImageSharp {
                fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        },
        fish: file(relativePath: { eq: "fish_pie.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        },
        continue: file(relativePath: { eq: "btn_continue.png" }) {
            childImageSharp {
                fluid(maxWidth: 128) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        },
        buysteam: file(relativePath: { eq: "btn_buySteam_bold.png" }) {
            childImageSharp {
                fluid(maxWidth: 736) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        },
        discord: file(relativePath: { eq: "btn_joinDiscord.png" }) {
            childImageSharp {
                fluid(maxWidth: 736) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        },
        itch: file(relativePath: { eq: "btn_findItch_bold.png" }) {
            childImageSharp {
                fluid(maxWidth: 736) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        },
        frame: file(relativePath: { eq: "trailer_frame.png" }) {
            childImageSharp {
                fixed(width: 1023) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                }
            }
        }
    }
`
